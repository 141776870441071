export const STATUS_RECEIVED = 'RECEIVED';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CONFIRMED = 'CONFIRMED';
export const STATUS_CANCELLED = 'CANCELLED';
export const STATUS_CLAIM_STARTED = 'CLAIM_STARTED';
export const STATUS_PENDING_PAYMENT = 'PENDING_PAYMENT';
export const ZENDESK_FILE_LIMIT = 5;
export const POLICY_STATUS = {
  [STATUS_ACTIVE]: 'activePolicyStatus',
  [STATUS_COMPLETED]: 'completedPolicyStatus',
  [STATUS_CONFIRMED]: 'confirmedPolicyStatus',
  [STATUS_CANCELLED]: 'cancelledPolicyStatus',
  [STATUS_CLAIM_STARTED]: 'claimStartedPolicyStatus',
  [STATUS_RECEIVED]: 'receivedPolicyStatus',
  [STATUS_PENDING_PAYMENT]: 'pendingPaymentStatus',
};

export const POLICY_TYPE = {
  APPLIANCES_COVER: 'appliances_cover',
  ECOMMERCE_INSURANCE: 'ecommerce_insurance',
  ECOMMERCE_PARCEL_INSURANCE: 'ecommerce_parcel_insurance',
  CONTENTS_COVER: 'contents_cover',
  CONTAINER_PROTECTION: 'container_protection',
  ELECTRONICS_COVER: 'electronics_cover',
  FURNITURE_COVER: 'furniture_cover',
  HOME_COVER: 'home_cover',
  INSTRUMENTS_COVER: 'instruments_cover',
  JEWELRY_COVER: 'jewelry_cover',
  LANDLORD_COVER: 'landlord_cover',
  OUTDOOR_COVER: 'outdoor_cover',
  PARCEL_COVER: 'parcel_cover',
  RENTAL_COVER: 'rental_cover',
  SPORTS_COVER: 'sports_cover',
  SUNGLASSES_COVER: 'sunglasses_cover',
  GADGET_COVER: 'gadget_cover',
  PARCEL_INSURANCE: 'parcel_insurance',
  LANDLORD_INSURANCE: 'landlord_insurance',
  PRODUCT_INSURANCE: 'product_insurance',
  PRODUCT_INSURANCE_AMZN: 'product_insurance_amzn',
  PROPERTY_COVER: 'full_property_protection',
  RENTAL_PROPERTY_COVER: 'rental_property_protection',
  TRAVEL_FLIGHT_DELAY_COVER: 'travel_flight_delay_cover',
  TRAVEL_GADGETS_ITEMS_COVER: 'travel_gadgets_items_cover',
  TRAVEL_FLIGHT_CANCELLATION_COVER: 'travel_flight_cancellation_cover',
  TRAVEL_FLIGHT_CANCELLATION_COVER_STANDALONE: 'travel_flight_cancellation_cover_standalone',
  TRAVEL_MEDICAL_COVER: 'travel_medical_cover',
  TRAVEL_MEDICAL_COVER_STANDALONE: 'travel_medical_cover_standalone',
  TRAVEL_PERSONAL_BAGGAGE: 'travel_personal_baggage',
  TRAVEL_PERSONAL_ELECTRONICS: 'travel_personal_electronics',
  TRAVEL_INSOLVENCY_INSURANCE_UNBUNDLED: 'travel_insolvency_insurance_unbundled',
  TRAVEL_FLIGHT_CANCELLATION_COVER_UNBUNDLED: 'travel_flight_cancellation_cover_unbundled',
  TRAVEL_MEDICAL_COVER_UNBUNDLED: 'travel_medical_cover_unbundled',
  EVENT_TICKET_PROTECTION: 'event_ticket_protection',
  COMPREHENSIVE_TRAVEL_INSURANCE: 'comprehensive_travel_insurance',
  COMPREHENSIVE_TRAVEL_INSURANCE_COVID: 'comprehensive_travel_insurance_covid',
  TRACKER_WARRANTY: 'tracker_warranty',
  EARTHQUAKE_COVER: 'earthquake_cover',
  TRAVEL_INSOLVENCEY_INSURANCE: 'travel_insolvency_insurance',
  PET_INSURANCE: 'pet_insurance',
  TRAVEL_COACH_PROTECTION: 'travel_coach_protection',
  TRAVEL_COVID: 'travel_covid_medical',
  RIDER_INSURANCE: 'rider_insurance',
  TRAVEL_TICKET_COVER: 'travel_ticket_cover',
  FLIGHT_DELAY_COVER: 'flight_delay_cover',
  CARAVAN_PROTECTION: 'caravan_protection',
  RENTERS_INSURANCE: 'renters_insurance',
  CYBER_INSURANCE: 'cyber_insurance',
  BUSINESS_INSURANCE: 'business_insurance',
  QUARANTINE_COVER: 'quarantine_cover',
  PROPERTY_PROTECTION: 'property_protection',
  ACCOMMODATION_CANCELLATION: 'accommodation_cancelation',
  DEVICE_PROTECTION: 'device_protection',
  COMPREHENSIVE_TRAVEL_INSURANCE_GROUND: 'comprehensive_travel_insurance_ground',
  HEALTH_COVER: 'health_cover',
  STAY_COMPREHENSIVE: 'stay_comprehensive',
  TRAVEL_TRAIN_CANCELLATION_COVER: 'travel_train_cancellation_cover',
  EVENT_TICKET_BP: 'event_ticket_bp',
  PRODUCT_LIABILITY_INSURANCE: 'product_liability_insurance',
  RENTAL_CARS: 'rental_cars',
  DRIVER_PROTECTION: 'driver_protection',
  HOME_WARRANTY_COVER: 'home_warranty_cover',
  PRODUCT_WARRANTY: 'product_warranty',
  KW_HOME_WARRANTY: 'home_warranty_cover',
  CLYDE_PRODUCT_INSURANCE: 'clyde_product_insurance',
  BUSINESS_INSURANCE_STAR: 'business_insurance_star',
  EXPEDIA_TRAVEL_INSURANCE: 'expedia_travel_insurance',
  RETAIL_BENEFITS: 'retail_benefits',
  LIFE_INSURANCE: 'life_insurance',
};

export const POLICY_TYPES = Object.values(POLICY_TYPE);

export const TRAVEL_POLICY_TYPES = [
  POLICY_TYPE.TRAVEL_FLIGHT_DELAY_COVER,
  POLICY_TYPE.TRAVEL_GADGETS_ITEMS_COVER,
  POLICY_TYPE.TRAVEL_FLIGHT_CANCELLATION_COVER,
  POLICY_TYPE.TRAVEL_MEDICAL_COVER,
  POLICY_TYPE.TRAVEL_PERSONAL_BAGGAGE,
  POLICY_TYPE.TRAVEL_PERSONAL_ELECTRONICS,
  POLICY_TYPE.COMPREHENSIVE_TRAVEL_INSURANCE,
  POLICY_TYPE.TRAVEL_INSOLVENCEY_INSURANCE,
  POLICY_TYPE.TRAVEL_COACH_PROTECTION,
  POLICY_TYPE.TRAVEL_MEDICAL_COVER_STANDALONE,
  POLICY_TYPE.TRAVEL_FLIGHT_CANCELLATION_COVER_STANDALONE,
  POLICY_TYPE.TRAVEL_TICKET_COVER,
  POLICY_TYPE.TRAVEL_TRAIN_CANCELLATION_COVER,
  POLICY_TYPE.EXPEDIA_TRAVEL_INSURANCE,
];

export const HOTEL_POLICY_TYPES = [POLICY_TYPE.STAY_COMPREHENSIVE];
export const PRODUCT_POLICY_TYPES = [POLICY_TYPE.PRODUCT_INSURANCE];
export const EVENT_POLICY_TYPES = [POLICY_TYPE.EVENT_TICKET_PROTECTION, POLICY_TYPE.EVENT_TICKET_BP];
export const PARCEL_POLICY_TYPES = [POLICY_TYPE.PARCEL_INSURANCE];
export const WARRANTY_POLICY_TYPES = [POLICY_TYPE.TRACKER_WARRANTY];
export const PARAMETRIC_POLICY_TYPES = [POLICY_TYPE.EARTHQUAKE_COVER];
export const PET_POLICY_TYPES = [POLICY_TYPE.PET_INSURANCE];
export const CYBER_POLICY_TYPES = [POLICY_TYPE.CYBER_INSURANCE];

export const QUOTE_VALUES = 'quoteValues';
export const USER_GEO = 'userGeo';
export const USER_COUNTRY = 'userCountry';
export const USER_CONTINENT = 'userContinent';

export const FORM_INPUT_TYPES = {
  ADDRESS: 'address',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  TEXT: 'text',
  EMAIL: 'email',
  TEL: 'tel',
  PASSWORD: 'password',
  PERCENTAGE: 'percentage',
  DATEPICKER: 'datepicker',
  CURRENCY_FORMATTED: 'currencyFormatted',
  IMAGE_SELECT: 'image_select',
  DATE: 'date',
  'DATETIME-LOCAL': 'datetime-local',
  CARD_OPTIONS: 'card',
} as const;

export const CURRENCIES = {
  USD: 'USD',
  AUD: 'AUD',
};

export const XPAY_CHARGE_BUNDLE_ID = 'xpay-charge-bundle';
export const XPAY_PAYOUT_BUNDLE_ID = 'xpay-widget-bundle';
export const WHITE_LABEL_PREFIX = 'quickbooks-';
export const PERMITTED_COUNTRY_CODE = 'US';

export const INVALID_QUOTES = 'invalid quotes';
export const PROD = 'production';

export const PAYMENT_METHODS = {
  PAYPAL: 'paypal',
  CREDIT_CARD: 'credit_card',
};

export const CURRENT_LOCALE = 'currentLocale';

export const IMPERSONATOR_TOKEN = 'impersonatorToken';

export const TOOLTIP_TIMEOUT_DELAY = 3000;

/* zendesk custom field IDs */
export const BOOKING_REFERENCE_FILED_ID = 360000121216;
export const LANGUAGE_FILED_ID = 5175584420751;
export const PARTNER_FIELD_ID = 360000138875;
export const NPS_RATING_FIELD_ID = 4542737830031;
export const FEEDBACK_FIELD_ID = 4993050735887;
export const TOUCH_POINT_FIELD_ID = 5027977852559;
export const EMAIL_FIELD_ID = 360000222275;

export const Z_INDEX = {
  HEADER: 'header',
  CONTENT: 'content',
  CONTENT_LAYER: 'contentLayer',
};

export const TIME_TYPE_OPTIONS = [
  { label: 'am', value: 'am' },
  { label: 'pm', value: 'pm' },
];

export const MORE_LANGUAGES = 'moreLanguages';
