import 'core-js/actual';
import 'regenerator-runtime/runtime';
import 'raf/polyfill'; // required by React 16
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider, ReactReduxContext } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';
import { LocaleObject, setLocale } from 'yup';
import '@whitelabel/xcover-shared/scss/index.scss';
import '@whitelabel/xcover-shared/css/cookieYes.css';
import moment from 'moment';
import GlobalXCoverStyle from '@whitelabel/xcover-shared/styled/GlobalXCoverStyle';
import {
  detectCookies,
  detectLanguage,
  detectLowerCaseINS,
  updateFontFamily,
  site,
  isSupportedBrowser,
} from '@whitelabel/helpers/site';
import { loadCountries, captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { CURRENT_LOCALE, USER_CONTINENT, USER_COUNTRY } from '@whitelabel/helpers/constants';
import languages from '@whitelabel/helpers/languages.json';
import theme from '@whitelabel/global-theme';
import { yupMessages } from '@whitelabel/helpers/forms/messages';
import { IError } from '@whitelabel/xcover-shared/helpers/types';
import { loadPolyfills, loadMomentLocale } from '@whitelabel/xcover-shared/helpers/polyfills';
import { storeUserGeoIpInfo } from '@whitelabel/xcover-shared/helpers/utils';
import UnsupportedBrowserPage from '@whitelabel/xcover-shared/components/UnsupportedBrowserPage';
import { configureCognito } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { rootSaga } from './actions';
import { makeStore, history, sagaMiddleware } from './store';
import { loadMessages } from './helpers/locales';
import App from './containers/App';
import NoCookiesErrorPage from './components/NoCookiesErrorPage';
import ErrorPage from './components/ErrorPage';
import CustomIntlProvider from './components/CustomIntlProvider';
import { initializeFullStory } from './helpers/utils';

const { NODE_ENV, REACT_APP_SENTRY_DSN: SENTRY_DSN } = process.env;

Sentry.init({
  dsn: SENTRY_DSN,
  attachStacktrace: true,
  ignoreErrors: [/(\$|jQuery) is not defined/i, /Can't find variable: (\$|jQuery)/i],
});

if (
  !window.isHeadless &&
  !window.location.pathname.includes('/pds/') &&
  (!window.location.pathname.includes('/partners/uber-driver') || Math.random() * 100 < 5)
) {
  initializeFullStory();
}

const bootstrapApp = async () => {
  try {
    if (window.top && window.top !== window) {
      window.top.location = window.location;
      if (!window.location.pathname.includes('/pds/') && !window.location.pathname.includes('/coi/')) {
        throw new Error('Website could not load properly due to clickjacking');
      }
    }

    detectLowerCaseINS();
    const isCookiesEnabled = detectCookies(Sentry);

    if (isCookiesEnabled && (!sessionStorage.getItem(USER_COUNTRY) || !sessionStorage.getItem(USER_CONTINENT))) {
      await storeUserGeoIpInfo();
    }

    const hasLanguage = detectLanguage();
    if (!hasLanguage) return;

    const { locale } = site;
    await loadPolyfills(locale);

    const { bidi } = languages[locale as keyof typeof languages];

    let messages;
    let fetchMessagesError = false;
    try {
      messages = await loadMessages(locale);
    } catch (error) {
      captureExceptionWithFullStory(error as IError);
      messages = {};
      fetchMessagesError = true;
    }

    const intl = {
      locale,
      messages,
      bidi,
    };

    const store = makeStore(intl);

    sagaMiddleware.run(rootSaga);

    let component;

    const supportedBrowser = isSupportedBrowser();
    if (!supportedBrowser) {
      component = <UnsupportedBrowserPage />;
    } else if (isCookiesEnabled && !fetchMessagesError) {
      configureCognito();
      await loadMomentLocale(locale);
      site.countries = await loadCountries(locale);

      setLocale(yupMessages as LocaleObject);
      sessionStorage.setItem(CURRENT_LOCALE, locale);
      if (locale === 'ar') {
        moment.updateLocale('ar', {
          weekdaysMin: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
          preparse: (string: string) => string,
          postformat: (string: string) => string,
        });
      }
      component = <App />;
    } else if (fetchMessagesError) {
      component = (
        <ErrorPage
          title="Fetch messages failed"
          button={{
            label: 'Reload',
            onClick: () => window.location.reload(),
          }}
          showGlobalNav={false}
        />
      );
    } else {
      component = <NoCookiesErrorPage />;
    }
    const root = createRoot(document.getElementById('root')!);

    // render app to root
    root.render(
      // @ts-ignore tofix: to upgrade react-redux major version
      <Provider store={store}>
        <CustomIntlProvider locale={locale}>
          <ThemeProvider theme={updateFontFamily(locale, theme)}>
            {
              // @ts-ignore tofix to upgrade connected-react-router major version
              <ConnectedRouter history={history} context={ReactReduxContext}>
                <GlobalXCoverStyle />
                {component}
              </ConnectedRouter>
            }
          </ThemeProvider>
        </CustomIntlProvider>
      </Provider>,
    );
  } catch (error) {
    captureExceptionWithFullStory(error as IError);
    if (NODE_ENV === 'development') {
      throw error;
    }
  }
};

bootstrapApp();
